<template>
  <div class="my-page">
    <div class="my-info-container">
      <div class="info-card">
        <div class="name-role-container">
          <div class="name-container">{{name}}</div>
          <div class="role-container">{{role}}</div>
        </div>
        <div class="main-info-container">
          <div class="info-item" v-for="(item) in infoList" :key="item.label">
            <div class="label">{{item.label}}</div>
            <div class="value">{{item.value}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="options-container">
      <router-link
        v-for="(item, index) in showOptions"
        :key="index"
        :to="item.path"
        class="option-item"
      >
        <div class="label" >
          {{item.label}}
        </div>
        <img src="@/assets/images/common/right-arrow.png" class="right-arrow-icon" />
      </router-link>
    </div>
    <Button size="large" @click="logout">退出登录</Button>
    <Tabbar />
  </div>

</template>

<script>
import Tabbar from '@/components/common/tabbar/index'
import Button from '@/components/common/button/CustomButton'
import * as user from '@/utils/user'

export default {
  components: {
    Tabbar,
    Button
  },
  data() {
    return {
      options: [
        {
          label: '我的团队',
          path: '/shidanliBusiness',
            isshow: user.getUserType() !== '属地业务'
        },
        {
          label: '修改密码',
          path: '/setting/changePassword',
            isshow: true
        },
        {
          label: '用户反馈',
          path: '/setting/feedback',
            isshow:true
        },
      ],
      infoList: [],
      name: '乔克',
      // type: user.getUserType(),
    }
  },
    computed: {
        showOptions: function() {
            return this.options.filter(function (item) {
                return item.isshow
            })
        }
    },

  mounted() {
    this.infoList = [
      {
        label: '账号',
        value: user.getUserName()
      },
      {
        label: '电话',
        value: user.getPhoneNumber()
      },
      {
        label: '所属经销商',
        value: user.getDealerName()
      }
    ]
    this.role = user.getRoles()
    this.name = user.getNickName()
      // alert(user.getUserType());
  },

  methods: {
    logout() {
      user.logout()
      window.location.replace('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.my-page {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  position: relative;
  background: #FAFBFC;
  z-index: 10;
  padding: 0 12px 92px;
  overflow: hidden;

  &:before {
    content: ' ';
    position: absolute;
    width: 750px;
    height: 750px;
    border-radius: 44%;
    background: #CD011F;
    top: -623px;
    left: -187.5px;
    z-index: -1;
  }

  .my-info-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .info-card {
      margin-top: 45px;
      width: 351px;
      height: 130px;
      border-radius: 10px;
      box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
      background: #FFFFFF;

      .name-role-container {
        width: 100%;
        height: 57px;
        box-sizing: border-box;
        padding: 20px 0 16px 20px;
        display: flex;
        align-items: center;

        .name-container {
          font-size: 15px;
          color: #2c2c2c;
        }

        .role-container {
          font-size: 12px;
          color: #cecece;
          margin-left: 11px;
        }
      }
      .main-info-container {
        width: 100%;
        box-sizing: border-box;
        padding-left: 22px;

        .info-item {
          display: flex;
          height: 17px;
          font-size: 12px;

          .label {
            color: #CECECE;
          }
          .value {
            color: #818181;
            margin-left: 7px;
          }
          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }
    }

  }
  .options-container {
    width: 100%;
    margin-top: 14px;
    box-sizing: border-box;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    margin-bottom: 45px;

    .option-item {
      width: 351px;
      height: 40px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: space-between;
      border-radius: 10px;
      background: white;
      padding: 0 12px;

      .label {
        font-size: 12px;
        color: #818181;
      }

      .right-arrow-icon {
        width: 10px;
        height: 10px;
      }

      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
}
</style>
